import React, { useContext, useEffect } from "react"
import "twin.macro"
import { graphql } from "gatsby"
import DynamicZone from "../components/organisms/dynamic-zone"
import {
  ACTIONS,
  createAction,
  GlobalDispatchContext,
} from "../utils/global-context"
import { useMediaQuery } from "../utils/use-media-query"
import DrawerSingleStory from "../components/organisms/episodes-drawer/drawer-single-story"
import Seo from "@atoms/seo"

const StoryPage = ({
  data: {
    ft: { story },
  },
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const isXL = useMediaQuery("(min-width: 1024px)")
  const seo = {
    meta_title: story.name,
    ...story.seo,
  }
  useEffect(() => {
    dispatch(createAction(ACTIONS.selectStory, story.id))
    return () => dispatch(createAction(ACTIONS.selectStory, null))
  })
  return (
    <>
      <Seo {...seo} />
      <div tw="bg-white relative lg:flex">
        <div tw="flex-grow">
          <DynamicZone components={story.containers} />
        </div>
        {isXL && (
          <div tw="relative flex-grow-0" className="tear-l-white">
            <div
              tw="sticky top-0 overflow-y-auto max-h-screen pt-6 xxl:px-2"
              css={`
                width: 224px;
                @media (min-width: 1440px) {
                  width: 244px;
                }
              `}
            >
              <DrawerSingleStory story={story} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query Story($id: ID!) {
    ft {
      story(id: $id) {
        id
        name
        containers {
          ...DynamicZone
        }
        episodes {
          ...EpisodeSummary
          locale
          title
          formatted_release_date
          description
        }
        seo {
          ...Seo
        }
      }
    }
  }
`

export default StoryPage
